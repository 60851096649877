import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {formatDate} from '@angular/common';
import {Pageable} from './pageable.model';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'aud-table',
  templateUrl: './aud-table.component.html',
  styleUrls: ['./aud-table.component.scss']
})
export class AudTableComponent implements OnInit, OnChanges {

  @Input('pageSizeOptions') pageSizeOptions: any;
  @Input('displayColumns') displayColumns: any;
  @Input('pageableItem') pageableItem: any;
  @Input('buttons') buttons: any;

  @Input('renderActions') renderActions = true;
  @Input('renderEdit') renderEdit = true;
  @Input('renderDelete') renderDelete = true;
  @Input('renderFilter') renderFilter = true;
  @Input('renderView') renderView = false;

  @Output() onSearch: EventEmitter<Pageable[]> = new EventEmitter<Pageable[]>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() onView: EventEmitter<any> = new EventEmitter<any>();

  // tslint:disable-next-line:ban-types
  dataSource: MatTableDataSource<Object> | undefined;
  displayedColumns = [];
  columnsHeader: any[] = [];
  customButtons: any[] = [];

  pageable: Pageable = new Pageable();

  private list: string[] = [];
  private valueField: string | undefined;

  constructor() {
  }

  public ngOnInit(): void {

    if (this.buttons) {
      this.customButtons = this.buttons;
    }

    if (this.renderActions || this.customButtons.length > 0) {
      this.displayedColumns = this.displayColumns.concat({field: 'action', label: ''});
    } else {
      this.displayedColumns = this.displayColumns;
    }
    // @ts-ignore
    this.columnsHeader = this.displayedColumns.map(x => x.field);

    this.updateContent();
  }

  public ngOnChanges(): void {
    this.updateContent();
  }

  private updateContent(): void {

    if (this.pageableItem !== undefined) {
      this.pageable = new Pageable();
      this.pageable.linesPerPage = this.pageableItem.size;
      this.pageable.length = this.pageableItem.totalElements;
      this.pageable.page = this.pageableItem.number;

      this.dataSource = new MatTableDataSource(this.pageableItem.content);
    }
  }

  public applyFilter(filterValue: string): void {
    this.pageable.searchText = filterValue;
    this.search();
  }

  public paginate(event: any): void {
    this.pageable.page = event.pageIndex;
    this.pageable.linesPerPage = event.pageSize;

    this.search();
  }

  sortData(event: any): void {
    this.pageable.orderBy = event.active;
    this.pageable.direction = event.direction;

    this.search();
  }

  public search(): void {
    if (this.onSearch != null) {
      this.onSearch.emit([this.pageable]);
    }
  }

  public delete(item: any): void {
    if (this.onDelete != null) {
      this.onDelete.emit([item]);
    }
  }

  public edit(item: any): void {
    if (this.onEdit != null) {
      this.onEdit.emit([item]);
    }
  }

  public view(item: any): void {
    if (this.onView != null) {
      this.onView.emit([item]);
    }
  }

  public mask(item: any, type: any): any {
    if (item) {
      if (type === 'date') {
        return formatDate(item, 'dd/MM/yyyy', 'en-US');
      }
    }
    return item;
  }

  public value(item: any, column: any): any {
    this.list = [];
    this.valueField = '';
    this.list = column.field.split('.');
    if (this.list.length === 1) {
      return this.mask(item[column.field], column.type);
    }
    this.list.forEach(obj => {
      if (this.valueField) {
        // @ts-ignore
        this.valueField = this.valueField[obj];
      } else {
        this.valueField = item[obj];
      }
    });
    return this.mask(this.valueField, column.type);
  }

  public handleAction(item: any): void {
    if (this.onAction != null) {
      this.onAction.emit([item]);
    }
  }
}
