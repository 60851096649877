import {Type} from '@angular/core';

import {BaseFilter} from './base/base.filter';

export class PrizeFilter extends BaseFilter {

    public email: string | undefined;

    public constructor() {
        super();
    }

    protected getType(): Type<BaseFilter> {
        return PrizeFilter;
    }
}
