import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate {
    public constructor(
        public auth: AuthService,
        public router: Router,
    ) {
    }

    public canActivate(): boolean {
        if (!this.auth.isAuthenticated()) {
                this.router.navigate(['/authentication/login']);
                return false;
        }

        return true;
    }
}
