import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {environment} from '../../environments/environment';
import {UserModel} from '../model/user.model';
import {UserFilter} from '../filter/user.filter';
import {ChallengesModel} from '../model/challenges.model';
import {isNullOrUndefined} from '@swimlane/ngx-datatable';

@Injectable()
export class ChallengeService extends CrudService<ChallengesModel, UserFilter> {
    protected entityClass = ChallengesModel;

    protected filterClass = UserFilter;

    protected entityPath = 'challenge';

    private storage: Storage = localStorage;

    private currentUserData: UserModel | undefined;

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    update(challenge: any): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('challenge', new Blob([JSON.stringify(challenge)], {type: 'application/json'}));

        if (!isNullOrUndefined(challenge.image64)) {
            formData.append('file', challenge.image64);
        }

        return this.httpClient.put(environment.apiUrl + `${this.entityPath}`, formData, {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        });
    }

    create(challenge: any): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('challenge', new Blob([JSON.stringify(challenge)], {type: 'application/json'}));

        if (!isNullOrUndefined(challenge.image64)) {
            formData.append('file', challenge.image64);
        }

        return this.httpClient.post(environment.apiUrl + `${this.entityPath}`, formData, {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        });
    }

}
