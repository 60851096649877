import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {environment} from '../../environments/environment';
import {UserModel} from '../model/user.model';
import {isNullOrUndefined} from '@swimlane/ngx-datatable';
import {AuctionModel} from '../model/auction.model';
import {AuctionFilter} from '../filter/auction.filter';

@Injectable()
export class AuctionService extends CrudService<AuctionModel, AuctionFilter> {
    protected entityClass = AuctionModel;

    protected filterClass = AuctionFilter;

    protected entityPath = 'auction';

    private storage: Storage = localStorage;

    private currentUserData: UserModel | undefined;

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }

    create(auction: any): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('auction', new Blob([JSON.stringify(auction)], {type: 'application/json'}));

        if (!isNullOrUndefined(auction.image64)) {
            formData.append('file', auction.image64);
        }

        return this.httpClient.post(environment.apiUrl + `${this.entityPath}`, formData, {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        });
    }

    update(auction: any): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('auction', new Blob([JSON.stringify(auction)], {type: 'application/json'}));

        if (!isNullOrUndefined(auction.image64)) {
            formData.append('file', auction.image64);
        }

        return this.httpClient.put(environment.apiUrl + `${this.entityPath}`, formData, {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        });
    }

}
