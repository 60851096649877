export class Pageable {

    public searchText = '';

    public length: number | undefined;

    public page = 0;
    public linesPerPage = 5;

    public orderBy = '';
    public direction = 'asc';

    constructor() {
    }
}
