import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {BaseService} from './base.service';

import {environment} from '../../environments/environment';
import {BaseModel} from '../model/base/base.model';
import {UserModel} from '../model/user.model';


@Injectable()
export class AuthService extends BaseService<BaseModel> {
    private jwtHelper: JwtHelperService = new JwtHelperService();

    private sessionStorage: Storage = sessionStorage;
    private localStorage: Storage = localStorage;

    private permissionsCache: Map<string, boolean> = new Map();

    public constructor(
        private router: Router,
        protected httpClient: HttpClient,
    ) {
        super();
    }

    public isAuthenticated(): boolean {
        const token = this.sessionStorage.getItem('token');
        // Check whether the token is expired and return
        // true or false
        try {
            return !this.jwtHelper.isTokenExpired(token as string);
        } catch (e) {
            this.clearToken();
            sessionStorage.clear();
            return false;
        }
    }

    public getUserName(): string {
        return this.getToken().name;
    }

    public getToken(): any {
        const token = this.getRawToken();

        if (token != null) {
            return this.jwtHelper.decodeToken(token);
        } else {
            return {
                userId: '',
                name: '',
            };
        }
    }

    public checkToken(token: string): boolean {
        try {
            if (!this.jwtHelper.isTokenExpired(token)) {
                this.sessionStorage.setItem('token', token);
                return true;
            }
        } catch (e) { }

        return false;
    }

    public getUserId(): string {
        return this.getToken().userId;
    }

    public getRawToken(): string {
        return this.sessionStorage.getItem('token') as string;
    }

    public login(username: string, password: string): Observable<boolean> {
        const formData = new HttpParams()
            .set('username', username)
            .set('password', password);
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'text/plain',
        });

        return this.httpClient.post(environment.authUrl + `auth/login`, formData, {headers, responseType: 'text'}).pipe(
            map(token => this.checkToken(token)),
            catchError(error => this.handleExceptions(error))
        );
    }

    public registerFinalUser(user: UserModel): Observable<boolean> {
        return this.httpClient.post(environment.authUrl + `auth/registerFinalUser`, user, {responseType: 'text'}).pipe(
            map((data) => data),
            catchError(error => this.handleExceptions(error))
        );
    }

    public logout(): any {
        // this.router.navigate([LoginRoutingNames.ROUTE_LOGIN]);
        return this.httpClient.post<any>(environment.authUrl + `auth/logout/${this.getRawToken()}`, {})
            .subscribe(res => {
                this.clearToken();
                this.localStorage.removeItem('aud_userData');
                this.localStorage.clear();
                window.location.reload();
        });
    }

    private clearToken(): void {
        this.sessionStorage.removeItem('token');
        this.sessionStorage.clear();
    }

}
