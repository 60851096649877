import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {PrizeModel} from '../model/prize.model';
import {PrizeFilter} from '../filter/prize.filter';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from '@swimlane/ngx-datatable';
import {environment} from '../../environments/environment';

@Injectable()
export class PrizeService extends CrudService<PrizeModel, PrizeFilter> {
    protected entityClass = PrizeModel;

    protected filterClass = PrizeFilter;

    protected entityPath = 'prize';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }


    update(prize: any): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('prize', new Blob([JSON.stringify(prize)], {type: 'application/json'}));

        if (!isNullOrUndefined(prize.image64)) {
            formData.append('file', prize.image64);
        }

        return this.httpClient.put(environment.apiUrl + `${this.entityPath}`, formData, {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        });
    }

    create(prize: any): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('prize', new Blob([JSON.stringify(prize)], {type: 'application/json'}));

        if (!isNullOrUndefined(prize.image64)) {
            formData.append('file', prize.image64);
        }

        return this.httpClient.post(environment.apiUrl + `${this.entityPath}`, formData, {
            headers: new HttpHeaders({
                Accept: 'application/json'
            })
        });
    }


}
