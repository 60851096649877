import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public constructor(
        private authService: AuthService
    ) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;

        // headers = this.addHeaderIfMissing(headers, 'Content-Type', 'application/json; charset=utf-8');
        // headers = this.addHeaderIfMissing(headers, 'Accept', 'application/json');

        const token: string = this.authService.getRawToken();
        if (token != null && token.trim() !== '') {
            headers = headers.append('Authorization', `Bearer ${token}`);
        }

        req = req.clone({
            headers
        });

        return next.handle(req);
    }

    private addHeaderIfMissing(headers: HttpHeaders, headerName: string, headerValue: string): HttpHeaders {
        if (headers.has(headerName)) {
            return headers;
        }

        return headers.set(headerName, headerValue);
    }
}
