import {BaseModel} from './base/base.model';

export abstract class AuditedModel extends BaseModel {
    public createdBy: string | undefined;

    public creationDate: Date | undefined;

    public modificationDate: Date | undefined;

    public modiåfiedBy: string | undefined;

    public constructor() {
        super();
    }
}
