import {AuditedModel} from './audited.model';
import {Deserializable} from './deserializable.model';

export class AuctionModel extends AuditedModel implements Deserializable {

    public id: string | undefined;
    public title: string | undefined;
    public description: string | undefined;
    public minValue: any | undefined;
    public amountMax: any | undefined;
    public amountMin: any | undefined;
    public startDate: any | undefined;
    public endDate: any | undefined;
    public status: string | undefined;
    public product: string | undefined;
    public imageType: string | undefined;
    public visible: boolean | undefined;
    public image64: any | undefined;
    public image: any | undefined;

    public constructor() {
        super();
    }

    public deserialize(input: any): any{
        Object.assign(this, input);
        return this;
    }
}
