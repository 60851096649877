<div class="bg-light p-10 p-r-20 p-l-20" *ngIf="renderFilter">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
  </mat-form-field>
</div>
<div class="table-rasponsive">
  <mat-table matSort
             [dataSource]="dataSource"
             (matSortChange)="sortData($event)">
    <ng-container *ngFor="let column of displayColumns" matColumnDef="{{column.field}}">
      <mat-header-cell *matHeaderCellDef mat-sort-header="{{column.sortableField ? column.sortableField : column.field}}" fxFlex.gt-sm="{{column.width}}" fxFlex.gt-xs="{{column.width}}" fxFlex="{{column.width}}"> {{ column.label}} </mat-header-cell>
      <mat-cell *matCellDef="let row" fxFlex.gt-sm="{{column.width}}" fxFlex.gt-xs="{{column.width}}" fxFlex="{{column.width}}"> {{value(row, column)}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" class="action-cell">
        <ng-container *ngIf="renderActions">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item *ngIf="renderEdit" (click)="edit(row)">Editar</button>
            <button mat-menu-item *ngIf="renderDelete"
                    (click)="delete(row)">Apagar</button>
            <button mat-menu-item *ngIf="renderView" (click)="view(row)">Visualizar</button>

          </mat-menu>
        </ng-container>

        <ng-container *ngIf="customButtons.length > 0">
          <button *ngFor="let button of customButtons"  mat-mini-fab matTooltip="{{button.tooltip}}" color="primary">
            <mat-icon matSuffixcolor="accent" (click)="handleAction(row)">{{button.icon}}</mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="columnsHeader"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnsHeader;"></mat-row>
  </mat-table>
  <mat-paginator
    [pageIndex]="pageable.page"
    [pageSizeOptions]="pageSizeOptions"
    [length]="pageable.length"
    [pageSize]="pageable.linesPerPage"
    (page)="paginate($event);"
  ></mat-paginator>
</div>
