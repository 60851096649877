import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {CrudService, EnumInfo, SubTypeInfo} from './crud.service';
import {environment} from '../../environments/environment';
import {UserModel} from '../model/user.model';
import {UserFilter} from '../filter/user.filter';

@Injectable()
export class UserService extends CrudService<UserModel, UserFilter> {
    protected entityClass = UserModel;

    protected filterClass = UserFilter;

    protected entityPath = '/user';

    private storage: Storage = localStorage;

    private currentUserData: UserModel | undefined;

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    public loggedUser(): Observable<UserModel> {
        return this.httpClient.get<UserModel>(environment.authUrl + `${this.entityPath}/loggedUser`).pipe(
            map((data: UserModel) => data),
            catchError(error => this.handleExceptions(error))
        );
    }

    public setUser(user: UserModel): void {
        this.storage.setItem('aud_userData', JSON.stringify(user));
    }

    public get currentUser(): UserModel {
        if (!this.currentUserData) {
            this.currentUserData = JSON.parse(this.storage.getItem('aud_userData') as string);
        }

        return this.currentUserData as UserModel;
    }

    protected getEnums(): EnumInfo[] {
        return [];
    }

    protected getSubTypes(): SubTypeInfo[] {
        return [];
    }


}
