import {Type} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {BaseService} from './base.service';

import {environment} from '../../environments/environment';
import {BaseFilter} from '../filter/base/base.filter';
import {BaseModel} from '../model/base/base.model';
import {Pageable} from '../aud-table/pageable.model';

export class SubTypeInfo {
    public name: string;

    public service: CrudService<any, any>;

    public constructor(
        name: string,
        service: CrudService<any, any>,
    ) {
        this.name = name;
        this.service = service;
    }
}

export class EnumInfo {
    public name: string;

    public enumObj: any;

    public constructor(
        name: string,
        enumObj: any,
    ) {
        this.name = name;
        this.enumObj = enumObj;
    }
}

export abstract class CrudService<E extends BaseModel, F extends BaseFilter> extends BaseService<E> {

    protected abstract entityPath: string;

    protected abstract filterClass: Type<F>;

    protected abstract entityClass: Type<E>;

    public constructor(
        protected httpClient: HttpClient,
    ) {
        super();
    }

    public search(filter: F): Observable<E[]> {
        return this.httpClient.post<E[]>(environment.apiUrl + `${this.entityPath}/search`, filter).pipe(
            map((data: E[]) => data.map(element => element)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public searchOne(filter: F): Observable<E> {
        return this.httpClient.post<E>(environment.apiUrl + `${this.entityPath}/search`, filter).pipe(
            map((data: E) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public list(): Observable<E[]> {
        return this.httpClient.get<E[]>(environment.apiUrl + `${this.entityPath}`).pipe(
            map((data: E[]) => data.map(element => element)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    findAll(pageable: Pageable = new Pageable()): Observable<any> {
        return this.httpClient.get(environment.apiUrl + `${this.entityPath}`);
    }

    findAllOffer(id: any): Observable<any> {
        return this.httpClient.get(environment.apiUrl + `offer/by-id-offer/${id}`);
    }


    findAllBids(id: any): Observable<any> {
        return this.httpClient.get(environment.apiUrl + `bids/by-offer/${id}`);
    }

    public update(entity: E): Observable<E>{
        return this.httpClient.put<E>(environment.apiUrl + this.entityPath, entity).pipe(
            map((data: E) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public count(filter: F): Observable<number> {
        return this.httpClient.post<number>(environment.apiUrl + `${this.entityPath}/count`, filter).pipe(
            map ((data: number) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public save(entity: E): Observable<E> {
        return this.httpClient.post<E>(environment.apiUrl + this.entityPath, entity).pipe(
            map((data: E) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public saveList(entities: E[]): Observable<E[]> {
        return this.httpClient.post<E[]>(environment.apiUrl + `${this.entityPath}/list`, entities).pipe(
            map((data: E[]) => data.map(e => e)),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public get(entityId: string): Observable<E> {
        return this.httpClient.get<E>(environment.apiUrl + `${this.entityPath}/${entityId}`).pipe(
            map((data: E) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public delete(entityId: string): Observable<void> {
        return this.httpClient.delete<void>(environment.apiUrl + `${this.entityPath}/${entityId}`).pipe(
            map ((data: void) => data),
            catchError(error => this.handleExceptions(error)),
        );
    }

    public buildFilterParameters(): F {
        return new this.filterClass();
    }

    protected abstract getSubTypes(): SubTypeInfo[];

    protected abstract getEnums(): EnumInfo[];

    public options(pageable: any): HttpParams {
        const options = new HttpParams()
            .set('searchText', pageable.searchText)
            .set('page', pageable.page.toString())
            .set('size', pageable.linesPerPage.toString())
            .set('sort', this.getSort(pageable));
        return options;
    }

    public getSort(pageable: any): any {
        const sort = pageable.orderBy ? pageable.orderBy.toString() : '';
        const order = pageable.direction ? pageable.direction.toString() : '';
        return sort + ',' + order;
    }
}
