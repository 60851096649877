import {AuditedModel} from './audited.model';
import {Deserializable} from './deserializable.model';

export class UserModel extends AuditedModel implements Deserializable {
    public addresses: any [] = [];
    public nameFull: string | undefined;
    public nameShort: string | undefined;
    public fiscalNumber: number | undefined;
    public status: any;

    public constructor() {
        super();
    }

    public deserialize(input: any): any{
        Object.assign(this, input);
        return this;
    }
}
